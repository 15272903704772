import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../.../../../../Axios.js";
import config from "../../../../config.js";
import Toast from "../../../common/SweetAlert.js";

export default function JForm(props) {
  const navigate = useNavigate();
  const [Alert, setAlert] = React.useState("");
  const [file, setFile] = React.useState(null);
  const [perc, setPerc] = React.useState(0);
  const [data, setData] = React.useState({
    job_id: "",
    title: "",
    description: "",
    skills: "",
    experience: "",
    timestamp: "",
    salary: "",
    location: "",
    jd: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.title && data.description && data.location) {
      axios.post("/job/new", data, { headers: config.headers }).then((res) => {
        setAlert(res.data.message);
        Toast.fire({
          icon: "success",
          title: "Job Added Successfully",
        });
        navigate("/careers");
      });
    } else {
      setAlert("Please fill all the fields");
    }
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (file) {
      setPerc(perc + 10);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("location", "careers/jd");
      setPerc(perc + 20);
      try {
        const res = await axios.post(
          "/file/upload",
          formData
        );
        setPerc(100);
        console.log(res.data.Location);
        Toast.fire({
          icon: "success",
          title: "JD Uploaded Successfully",
        });
        setData((prev) => {
          return {
            ...prev,
            jd: res.data.Location,
          };
        });
        setTimeout(() => {
          setPerc(0);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Please Select a File",
      });
    }
  };

  return (
    <div className="col-12">
      <h4 class="fw-bold mb-4">
        <span className="text-muted fw-light">Home / Career</span> / Job Form
        <span style={{ float: "right" }}>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => navigate("/careers")}
          >
            <i className="fas fa-house"></i> Home
          </button>
        </span>
      </h4>
      <div className="card mb-4">
        <div className="card-body">
          <form>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Job Title</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="title"
                  onChange={handleChange}
                  value={data.title}
                  className="form-control"
                  placeholder="Enter Job Title"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Description</label>
              <div className="col-sm-10">
                <textarea
                  type="text"
                  name="description"
                  rows={"4"}
                  onChange={handleChange}
                  value={data.description}
                  className="form-control"
                  placeholder="Enter Job Description"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Skills Required</label>
              <div className="col-sm-10">
                <textarea
                  type="text"
                  name="skills"
                  rows={"2"}
                  onChange={handleChange}
                  value={data.skills}
                  className="form-control"
                  placeholder="Enter Skills Required Separated by Comma"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Experience</label>
              <div className="col-sm-10">
                <div className="input-group input-group-merge">
                  <input
                    type="text"
                    name="experience"
                    onChange={handleChange}
                    value={data.experience}
                    className="form-control"
                    placeholder="Enter Experience"
                  />
                  <span className="input-group-text">Years</span>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Salary</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="salary"
                  onChange={handleChange}
                  value={data.salary}
                  className="form-control"
                  placeholder="Enter Salary"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Job Location</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="location"
                  onChange={handleChange}
                  value={data.location}
                  className="form-control"
                  placeholder="Enter Job Location"
                />
              </div>
            </div>
            <div className="mb-3 col-md-12">
              <label className="form-label">
                Upload Reference Document or JD
              </label>
              {perc !== 0 ? (
                <div className="progress m-3" style={{ width: "100%" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: perc + "%" }}
                    aria-valuenow={perc}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    {perc}%
                  </div>
                  <p></p>
                </div>
              ) : null}
              {!data.jd && (
                <div className="row">
                  <span className="col-8">
                    <input
                      className="form-control"
                      type="file"
                      onChange={handleFile}
                      name="resume"
                    />
                  </span>
                  <span className="col-4">
                    <button
                      onClick={handleFileUpload}
                      className="btn btn-primary btn-sm"
                      style={{ float: "right" }}
                    >
                      Upload
                    </button>
                  </span>
                </div>
              )}
              {data.jd && (
                <div className="row">
                  <div class="col-12 alert alert-primary" role="alert">
                    <span style={{ float: "left" }}>
                      File Uploaded Successfully
                    </span>
                    <span style={{ float: "right" }}>
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#attachmentModal"
                        onClick={() => props.setGlobal({ doc: data.jd })}
                      >
                        <i className="fa-solid fa-eye" /> Preview
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-2">
              <span style={{ float: "left" }}>
                {Alert && (
                  <div class="alert alert-dark mb-0" role="alert">
                    {Alert}
                  </div>
                )}
              </span>

              <span style={{ float: "right" }}>
                <button onClick={handleSubmit} className="btn btn-primary m-2">
                  Submit
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
