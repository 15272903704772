import React from "react";
import axios from "../../../../Axios.js";
import config from "../../../../config.js";
import NoRecord from "../../../common/NoRecord";
import Loader from "../../../common/Loader";

export default function Newsletter() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios
      .get("/newsletter/retrieveAll", { headers: config.headers })
      .then((res) => {
        setData(res.data);
      });
  }, []);

  return (
    <div className="col-12">
      <h4 class="fw-bold mb-4">
        <span class="text-muted fw-light">Home /</span> Newsletter
      </h4>
      {!data ? (
        <Loader />
      ) : data.length === 0 ? (
        <NoRecord />
      ) : (
        <div className="row">
          {data.map((item) => {
            return (
              <div className="col-md-4 col-12">
                <div className="card bg-primary text-white mb-3 p-1">
                  <p className="card-text text-center">{item.Email_ID}</p>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
