import axios from "../../../../Axios.js";
import config from "../../../../config.js";
import React from "react";
import { useNavigate } from "react-router-dom";
import Toast from "../../../common/SweetAlert.js";
import Upload from "../../../../assets/img/icon/upload.png";

export default function PForm(props) {
  const navigate = useNavigate();
  const [file, setFile] = React.useState(null);
  const [ifile, setiFile] = React.useState(null);
  const [avtar, setAvtar] = React.useState(Upload);
  const [perc, setPerc] = React.useState(0);
  const [data, setData] = React.useState({
    project_img: "",
    project_name: "",
    project_description: "",
    technology: "",
    project_report: "",
    project_image: "",
    project_features1: "",
    project_features2: "",
    project_features3: "",
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.project_name && data.project_description && data.technology) {
      axios.post("/project/add", data,{ headers: config.headers } ).then((res) => {
        Toast.fire({
          icon: "success",
          title: res.data,
        });
        navigate("/projects");
      });
    } else {
      Toast.fire({
        icon: "error",
        title: "Please fill all the fields",
      });
    }
    console.log(data);
  };

  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };

  const handleiFile = (e) => {
    setAvtar(URL.createObjectURL(e.target.files[0]));
    setiFile(e.target.files[0]);
  };

  const handleiFileUpload = async (e) => {
    e.preventDefault();
    if (ifile) {
      const formData = new FormData();
      formData.append("file", ifile);
      formData.append("location", "projects");
      setPerc(perc + 20);
      try {
        const res = await axios.post(
          "/file/upload",
          formData
        );
        Toast.fire({
          icon: "success",
          title: "Project Preview Uploaded Successfully",
        });
        setPerc(100);
        setTimeout(() => {
          setPerc(0);
        }, 1000);
        setData((prev) => {
          return {
            ...prev,
            project_img: res.data.Location,
          };
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Please Select a File",
      });
    }
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (file) {
      setPerc(perc + 10);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("location", "projects");
      setPerc(perc + 20);
      try {
        const res = await axios.post(
          "/file/upload",
          formData
        );
        setPerc(100);
        console.log(res.data.Location);
        Toast.fire({
          icon: "success",
          title: "JD Uploaded Successfully",
        });
        setData((prev) => {
          return {
            ...prev,
            project_report: res.data.Location,
          };
        });
        setTimeout(() => {
          setPerc(0);
        }, 1000);
      } catch (err) {
        console.log(err);
      }
    } else {
      Toast.fire({
        icon: "error",
        title: "Please Select a File",
      });
    }
  };

  return (
    <div className="col-12">
      <h4 class="fw-bold mb-4">
        <span className="text-muted fw-light">Home / Project </span>/ Add
        Project
        <span style={{ float: "right" }}>
          <button
            className="btn btn-primary btn-sm m-1"
            onClick={() => navigate("/projects")}
          >
            <i className="fas fa-house"></i>
          </button>
        </span>
      </h4>
      <div className="card mb-4">
        <div className="card-body">
          <form>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Project Name</label>
              <div className="col-sm-10">
                <input
                  type="text"
                  name="project_name"
                  onChange={handleChange}
                  value={data.project_name}
                  className="form-control"
                  placeholder="Enter Project Name"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Description</label>
              <div className="col-sm-7">
                <textarea
                  type="text"
                  name="project_description"
                  rows={"6"}
                  onChange={handleChange}
                  value={data.project_description}
                  className="form-control"
                  placeholder="Enter Project Description"
                />
              </div>
              <div className="col-sm-3 text-center">
                <label>
                  <img
                    src={avtar}
                    alt="user-avatar"
                    className="d-block rounded"
                    style={{ maxWidth: "100%" }}
                  />
                  <input
                    type="file"
                    onChange={handleiFile}
                    className="account-file-input"
                    accept="image/png image/jpg"
                    disabled={data.project_img ? true : false}
                    hidden
                  />
                </label><br />
                {!data.project_img && <div className="text-center">
                  <i className="fa-solid fa-upload" onClick={handleiFileUpload} />
                </div>}
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">Technology</label>
              <div className="col-sm-10">
                <textarea
                  type="text"
                  name="technology"
                  rows={"2"}
                  onChange={handleChange}
                  value={data.technology}
                  className="form-control"
                  placeholder="Enter Technology"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">
                Project Features 1
              </label>
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    type="text"
                    name="project_features1"
                    onChange={handleChange}
                    value={data.project_features1}
                    className="form-control"
                    placeholder="Enter Experience"
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">
                Project Features 2
              </label>
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    type="text"
                    name="project_features2"
                    onChange={handleChange}
                    value={data.project_features2}
                    className="form-control"
                    placeholder="Enter Experience"
                  />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <label className="col-sm-2 col-form-label">
                Project Features 3
              </label>
              <div className="col-sm-10">
                <div className="input-group">
                  <input
                    type="text"
                    name="project_features3"
                    onChange={handleChange}
                    value={data.project_features3}
                    className="form-control"
                    placeholder="Enter Experience"
                  />
                </div>
              </div>
            </div>
            <div className="mb-3 col-md-12">
              <label className="form-label">Upload Project Details</label>
              {perc !== 0 ? (
                <div className="progress m-3" style={{ width: "100%" }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: perc + "%" }}
                    aria-valuenow={perc}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  >
                    {perc}%
                  </div>
                  <p></p>
                </div>
              ) : null}
              {!data.project_report && (
                <div className="row">
                  <span className="col-8">
                    <input
                      className="form-control"
                      type="file"
                      onChange={handleFile}
                      name="resume"
                    />
                  </span>
                  <span className="col-4">
                    <button
                      onClick={handleFileUpload}
                      className="btn btn-primary btn-sm"
                      style={{ float: "right" }}
                    >
                      Upload
                    </button>
                  </span>
                </div>
              )}
              {data.project_report && (
                <div className="row">
                  <div class="col-12 alert alert-primary" role="alert">
                    <span style={{ float: "left" }}>
                      File Uploaded Successfully
                    </span>
                    <span style={{ float: "right" }}>
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#attachmentModal"
                        onClick={() => props.setGlobal({ doc: data.jd })}
                      >
                        <i className="fa-solid fa-eye" /> Preview
                      </button>
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-2">
              <span style={{ float: "right" }}>
                <button onClick={handleSubmit} className="btn btn-primary m-2">
                  Submit
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
