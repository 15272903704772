import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../Axios.js";
import config from "../../../../config.js";
import Swal from "sweetalert2";
import Toast from "../../../common/SweetAlert.js";

export default function Projects(props) {
  const navigate = useNavigate();
  const [project, setProject] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  React.useEffect(() => {
    axios.get("/project/retrieveAll").then((res) => {
      setProject(res.data);
    });
  }, [load]);

  function deleteProject(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`/project/delete/${id}`, { headers: config.headers })
          .then((res) => {
            Toast.fire({
              icon: "success",
              title: "Project Deleted Successfully",
            });
            setLoad(true);
          });
      }
    });
  }

  return (
    <div className="col-12">
      <h4 class="fw-bold mb-4">
        <span className="text-muted fw-light">Home / </span>Project
        <span style={{ float: "right" }}>
          <button
            className="btn btn-primary btn-sm m-1"
            onClick={() => navigate("/projects/addProject")}
          >
            <i className="fas fa-plus"></i> Add Project
          </button>
        </span>
      </h4>
      <div className="row">
        {project.map((item) => {
          return (
            <div className="list-group-item list-group-item-action flex-column align-items-start">
              <div className="d-flex justify-content-between w-100 p-2">
                <h5>{item.project_name}</h5>
              </div>
              <div className="row">
                <div className="col-md-4 col-12">
                  <img src={item.project_img} className="img-fluid" />
                </div>
                <div className="col-md-8 col-12">
                  <div className="row">
                    <p className="mb-1">
                      <dl className="row mt-2">
                        <dt className="col-sm-2">Project Description</dt>
                        <dd className="col-sm-10">
                          {item.project_description}
                        </dd>
                        <dt className="col-sm-2">Technology</dt>
                        <dd className="col-sm-10">{item.technology}</dd>
                        <dt className="col-sm-2">Features</dt>
                        <dd className="col-sm-10">
                          {item.project_features1}
                          <br />
                          {item.project_features2}
                          <br />
                          {item.project_features2}
                        </dd>
                      </dl>
                      {item.project_report && (
                        <button
                          className="btn btn-primary btn-sm"
                          type="button"
                          style={{ float: "right" }}
                          data-bs-toggle="modal"
                          data-bs-target="#attachmentModal"
                          onClick={() =>
                            props.setGlobal({ doc: item.project_report })
                          }
                        >
                          <i className="fa-solid fa-eye" /> Report
                        </button>
                      )}
                      <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        style={{ float: "right" }}
                        onClick={() => deleteProject(item._id)}
                      >
                        <i className="fa-solid fa-trash" /> Delete
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
