import React from "react";
import axios from "../../../../Axios.js";
import config from "../../../../config.js";
import NoRecord from "../../../common/NoRecord";
import Loader from "../../../common/Loader";

export default function Feedback() {
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    axios.get("/feedback/All", { headers: config.headers }).then((res) => {
      setData(res.data);
    });
  }, []);

  return (
    <div className="col-12">
      <h4 class="fw-bold mb-4">
        <span class="text-muted fw-light">Home /</span> Feedback
      </h4>
      {!data ? (
        <Loader />
      ) : data.length === 0 ? (
        <NoRecord />
      ) : (
        <div className="row">
          {data.map((item, index) => {
            return (
              <div className="col-md-4 col-12 p-1">
                <div className="card">
                  <div className="card-body">
                    <small
                      className="text-light fw-semibold"
                      style={{ float: "right" }}
                    >
                      {item.rating} ⭐
                    </small>
                    <figure className="text-center mt-2">
                      <blockquote className="blockquote">
                        <p className="mb-0">{item.feedback}</p>
                      </blockquote>
                      <figcaption className="blockquote-footer">
                        {item.name}
                        <br />
                        <cite title="Source Title">{item.email}</cite> |{" "}
                        <cite title="Source Title">{item.phone}</cite>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}
