import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../Axios.js";
import config from "../../../../config.js";
import NoRecord from "../../../common/NoRecord";
import Loader from "../../../common/Loader";
import Swal from "sweetalert2";
import Toast from "../../../common/SweetAlert.js";

export default function JobApplications(props) {
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  React.useEffect(() => {
    axios
      .get("/jobApplication/All", { headers: config.headers })
      .then((res) => {
        setData(res.data.reverse());
      });
  }, [load]);

  function deleteJob(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`/jobApplication/delete/${id}`, { headers: config.headers })
          .then((res) => {
            Toast.fire({
              icon: "success",
              title: "Application Deleted Successfully",
            });
            setLoad(true);
          });
      }
    });
  }

  return (
    <div className="col-12">
      <h4 class="fw-bold mb-4">
        <span class="text-muted fw-light">Home / Careers</span> / Job
        Applications
        <span style={{ float: "right" }}>
          <button
            className="btn btn-primary btn-sm"
            onClick={() => navigate("/careers")}
          >
            <i className="fas fa-house"></i> Home
          </button>
        </span>
      </h4>
      <div className="row">
        <div className="col-12">
          <div class="card">
            {!data ? (
              <Loader />
            ) : data.length === 0 ? (
              <NoRecord />
            ) : (
              <div className="table-responsive text-nowrap">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S No.</th>
                      <th>Application No.</th>
                      <th>Post</th>
                      <th>Name</th>
                      <th>Expand</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>
                            {item.application_id}
                            <br />
                            <small>{item.timestamp}</small>
                          </td>

                          <td style={{ width: "100px" }}>{item.post}</td>
                          <td>
                            <small>
                              {item.name}
                              <br />
                              {item.email}
                              <br />
                              {item.phone}
                            </small>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary btn-sm"
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#attachmentModal"
                              onClick={() =>
                                props.setGlobal({ doc: item.resume })
                              }
                            >
                              <i className="fa-solid fa-eye" /> Resume
                            </button>
                            <button
                              className="btn btn-primary btn-sm m-1"
                              type="button"
                              onClick={() => deleteJob(item.application_id)}
                            >
                              <i className="fa-solid fa-trash " />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
