import React from "react";
import axios from "../../../../Axios.js";
import config from "../../../../config.js";

export default function A(props) {
  const [len, setLen] = React.useState(0);

  React.useEffect(() => {
    axios.get("/query/retrieveAll", { headers: config.headers }).then((res) => {
      setLen(res.data.length);
    });
  }, [len]);

  return (
    <div className="col-lg-6 col-md-12 col-6 mb-4">
      <div className="card text-center" style={{ height: "180px" }}>
        <div className="card-body">
          <div className="card-title d-flex align-items-start justify-content-center">
            <div className="avatar flex-shrink-0">
              <i className="fas fa-question fa-3x"></i>
            </div>
          </div>
          <span className="fw-semibold d-block mb-1">Total Queries</span>
          <h3 className="card-title mb-2">{len}</h3>
        </div>
      </div>
    </div>
  );
}
