import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "./Axios.js";
import Swal from "sweetalert2";
import Login from "./components/auth/Login";
import Reset from "./components/auth/Reset";
import Admin from "./components/admin/Admin";

function App() {
  const navigate = useNavigate();
  const [user, setUser] = React.useState([]);

  async function logout() {
    Swal.fire({
      title: "Logout ?",
      text: "Are you sure you want to logout?",
      icon: "question",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("token");
        window.location.reload();
      }
    });
  }

  React.useEffect(() => {
    axios
      .get("/")
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    const token = localStorage.getItem("token");
    const path = window.location.pathname;
    if (token) {
      axios
        .get(`/auth/validate/${token}`)
        .then((res) => {
          if (res.data.isLogged) {
            setUser(res.data.user);
            if (path === "/login" || path === "/reset") {
              navigate("/");
            }
          } else {
            console.log("Failed to Authenticate");
            if (path === "/login" || path === "/reset") {
              navigate(path);
            } else {
              navigate("/");
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  return (
    <Routes>
      <Route
        exact
        path="/*"
        element={
          <>
            {user.length === 0 ? (
              <Login setUser={setUser} />
            ) : user.role === "admin" ? (
              <Admin user={user} logout={logout} />
            ) : (
              <h1>Invalid Role</h1>
            )}
          </>
        }
      />
      <Route exact path="/login" element={<Login setUser={setUser} />} />
      <Route exact path="/reset" element={<Reset />} />
    </Routes>
  );
}

export default App;
